export interface Instance {
    ownerId: string;
    name: string;
    id?: string;

    places?: InstancePlace[];

    minRating?: number;
    dateFormat?: DateFormat;
    speed?: number;
    fontFamily?: string;
    fontSize?: number;
    size?: number;

    fullWidth?: boolean;
    textOnly?: boolean;
    randomOrder?: boolean;
    showSourceLogo?: boolean;
    textColor?: string;
    backgroundColor?: string;

    planId?: string;
    trialEndsAt?: Date;
}

export interface InstancePlace {
    placeId: string;
    placeName: string;
};

export type DateFormat = 'DD/MM/YYYY' | 'MM/DD/YYYY' | 'Hide';
export const DATE_FORMATS_ORDER: DateFormat[] = ['DD/MM/YYYY', 'MM/DD/YYYY', 'Hide'];
export const DATE_FORMAT_LABELS: Record<DateFormat, string> = {
    'DD/MM/YYYY': 'Day/Month/Year',
    'MM/DD/YYYY': 'Month/Day/Year',
    'Hide': 'Hide',
}

