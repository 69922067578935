import { Button, ButtonGroup, FormCheck, FormControl } from 'react-bootstrap'
import { Instance } from '../models/shared/Instance';
import { useState } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

const DEFAULT_SPEED = 1;
const DEFAULT_SIZE = 1;

const FONT_OPTIONS = [
    { label: "<Default>", value: "" },
    { "label": "Arial", "value": "Arial, sans-serif" },
    { "label": "Verdana", "value": "Verdana, sans-serif" },
    { "label": "Georgia", "value": "Georgia, serif" },
    { "label": "Times New Roman", "value": "\"Times New Roman\", Times, serif" },
    { "label": "Courier New", "value": "\"Courier New\", Courier, monospace" }
]
interface Props {
    instance: Instance
    autoUpdateInstance: (data: Partial<Instance>) => void
}

function InstanceOptionsDesign(props: Props) {
    const { autoUpdateInstance, instance } = props
    const [customColors, setCustomColors] = useState(!!(instance.textColor || instance.backgroundColor))
    const [customSize, setCustomSize] = useState(!!instance.size && instance.size !== DEFAULT_SIZE)
    const [speed, setSpeed] = useState(instance.speed || DEFAULT_SPEED);
    const [size, setSize] = useState(instance.size || DEFAULT_SIZE);


    const fontFamily = instance.fontFamily || '';

    const toggleCustomColors = () => {
        if (customColors) {
            autoUpdateInstance({ textColor: '', backgroundColor: '' })
        }

        setCustomColors(!customColors)
    }

    const toggleCustomSize = () => {
        if (customSize) {
            autoUpdateInstance({ size: DEFAULT_SIZE })
        }

        setCustomSize(!customSize)
    }



    return <>
        <label>Speed</label>
        <div className="mb-3">

            <Slider
                min={-2}
                max={2}
                step={0.05}
                value={-speed}
                onChangeComplete={(value) => autoUpdateInstance({ speed: -Number(value) || 0.01 })}
                onChange={(value) => setSpeed(-value)}
            />
        </div>

        <label>Font</label>
        <div className="mb-3">

            <ButtonGroup>
                {FONT_OPTIONS.map(option => {
                    const isSelected = fontFamily === option.value;
                    return <Button key={option.value}
                        style={{ fontFamily: option.value }}
                        size="sm"
                        onClick={() => autoUpdateInstance({ fontFamily: option.value })}
                        variant={isSelected ? 'primary' : "outline-primary"}>
                        {option.label}
                    </Button>
                })}
            </ButtonGroup>
            <p className="text-muted">*Default font inherits font from target site</p>
        </div>


        <FormCheck
            type="switch"
            label="Full width"
            className="pointer"
            checked={!!instance.fullWidth}
            onChange={(e) => autoUpdateInstance({ fullWidth: e.target.checked })}
        />

        <FormCheck
            type="switch"
            label="Show source logo"
            className="pointer"
            checked={!!instance.showSourceLogo}
            onChange={(e) => autoUpdateInstance({ showSourceLogo: e.target.checked })}
        />
        <FormCheck
            type="switch"
            label="Set custom colors"
            className="pointer"
            checked={!!customColors}
            onChange={() => toggleCustomColors()}
        />

        {customColors && <div className='p-2 d-flex gap'>
            <div className='center'>
                <FormControl
                    onChange={(e) => autoUpdateInstance({ textColor: e.target.value })}
                    type="color"
                    defaultValue={instance.textColor || "#000"}
                />
                <label>Text color</label>
            </div>

            <div className='center'>
                <FormControl
                    onChange={(e) => autoUpdateInstance({ backgroundColor: e.target.value })}
                    type="color"
                    defaultValue={instance.backgroundColor || "#FFF"}
                />
                <label>Background color</label>
            </div>
        </div>}

        <FormCheck
            type="switch"
            label="Set custom size"
            className="pointer"
            checked={!!customSize}
            onChange={() => toggleCustomSize()}
        />

        {customSize && <div className='p-2'>
            <label>Size</label>
            <Slider
                min={1}
                max={2.5}
                step={0.05}
                value={size}
                onChangeComplete={(value) => autoUpdateInstance({ size: Number(value) })}
                onChange={(value) => setSize(Number(value))}
            />
        </div>}
    </>

}

export default InstanceOptionsDesign
